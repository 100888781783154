// @mui material components
import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Trubin
                    <br />
                    Anatoliy
                  </>
                }
                description={
                  <>
                    Position - Goalkeeper
                    <br />
                    Age - 22
                    <br />
                    Born - Ukraine
                    <br />
                    Date of Born - 01/08/2001
                    <br />
                    Coast - 60,000,000 €
                  </>
                }
              />
              <RotatingCardBack
                image={bgBack}
                title={"NFTs and Loot Boxes"}
                description="Purchase NFT avatars and loot boxes with unique gear that enhances your ability to earn token."
                action={{
                  type: "internal",
                  route: "/sections/page-sections/page-headers",
                  label: "Comming soon...",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title="Simple Registration"
                  description="Use only Telegram integration by one movment"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  title="Choose Your Team"
                  description="Select one Favorite team and earn Tokens."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="Token Mining"
                  description="Earn Tokens by only Telegram login and enjoy the football"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  title="Easy Staking"
                  description="Get your Power of Goals in football events each day"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
