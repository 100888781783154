// @mui material components
// import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Импорт изображения
import mainStakeImage from "assets/images/main_stake.jpg";

function BuiltByDevelopers() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={8}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", mx: "auto" }}
        >
          <MKTypography variant="h2" fontWeight="bold" mb={1}>
            Your Potentials with PRO ARENA
          </MKTypography>
          <MKTypography variant="body1" color="secondary" mb={3}>
            Join us to be part of a community that empowers fans and fuels dreams.
          </MKTypography>
        </Grid>
      </Container>
      <MKBox
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        borderRadius="xl"
        sx={{
          backgroundImage: `url(${mainStakeImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "400px", // Adjust height as needed
          p: 3,
        }}
      >
        <MKButton
          color="primary"
          variant="contained"
          href="https://t.me/PRO_ARENA_bot?start=667fdecbdec60ce8fe035ca0"
          sx={{
            position: "relative",
            bottom: 0,
            right: 0,
            mt: "auto",
          }}
        >
          Start Your Journey
        </MKButton>
      </MKBox>
    </MKBox>
  );
}

export default BuiltByDevelopers;
