// import React from "react";
// import * as ReactDOMClient from "react-dom/client";
// import { BrowserRouter } from "react-router-dom";
// import App from "App";

// const container = document.getElementById("root");

// // Create a root.
// const root = ReactDOMClient.createRoot(container);

// root.render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>
// );
// import React from "react";
// import ReactDOM from "react-dom";
// import App from "./App";
// import { UserProvider } from "components/UserContext/UserContext";

// ReactDOM.render(
//   <UserProvider>
//     <App />
//   </UserProvider>,
//   document.getElementById("root")
// );
// index.js
import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { UserProvider } from "components/UserContext/UserContext";

const container = document.getElementById("root");

// Create a root.
const root = ReactDOMClient.createRoot(container);
console.log("pre render");
root.render(
  <BrowserRouter>
    <UserProvider>
      <App />
    </UserProvider>
  </BrowserRouter>
);
