// import React from "react";
// import TelegramLoginButton from "react-telegram-login";

// const TelegramLogin = () => {
//   const handleTelegramResponse = (response) => {
//     console.log("Telegram response:", response);

//     // Сохраните данные пользователя в sessionStorage
//     sessionStorage.setItem("user", JSON.stringify(response));

//     // Отправляем данные пользователя на сервер для создания сессии
//     fetch("http://localhost:3002/auth/telegram/callback", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(response),
//     })
//       .then((res) => res.json())
//       .then((data) => {
//         if (data.success) {
//           console.log("User authenticated successfully");
//           // Перенаправляем пользователя на защищенную страницу
//           window.location.href = "/dashboard";
//         } else {
//           console.error("Authentication failed:", data.message);
//         }
//       })
//       .catch((error) => {
//         console.error("Error sending authentication data:", error);
//       });
//   };

//   return (
//     <div>
//       <h1>Login with Telegram</h1>
//       <TelegramLoginButton
//         botName="PRO_ARENA_bot" // Укажите имя вашего бота
//         dataOnauth={handleTelegramResponse}
//         buttonSize="large"
//         requestAccess="write"
//       />
//     </div>
//   );
// };

// export default TelegramLogin;
// Рабочий вариант ----------
import React from "react";
import TelegramLoginButton from "react-telegram-login";

const TelegramLogin = () => {
  const handleTelegramResponse = (response) => {
    console.log("Telegram response:", response);

    sessionStorage.setItem("user", JSON.stringify(response));
    console.log("authed 1")

    fetch("https://proarena.io/auth/telegram/callback", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(response),
    })
      .then((res) => {
        if (res.ok) {
          return res.text(); 
        } else {
          return res.text().then((text) => {
            console.log(`Error: ${text}`)
            throw new Error(`Error: ${text}`); 
          });
        }
      })
      .then((data) => {
        console.log("Response from server:", data);
        if (data === "Authentication successful") {
          window.location.href = "/dashboard";
        } else {
          console.error("Authentication failed:", data);
        }
      })
      .catch((error) => {
        console.error("Error sending authentication data:", error);
      });

  };

  return (
    <div>
      <h1>Login with Telegram</h1>
      <TelegramLoginButton
        botName="PRO_ARENA_bot" // Имя вашего бота
        dataOnauth={handleTelegramResponse}
        buttonSize="large"
        requestAccess="write"
      />
    </div>
  );
};
export default TelegramLogin;
