import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "components/MatchCard/MatchCard.css"; // Импорт CSS файла
import worldImage from "components/MatchCard/world.png"; // Импорт изображения "World"

const MatchCard = ({ match }) => {
  const {
    date,
    time,
    status,
    match_country_name,
    league_name,
    localTeam,
    visitorTeam,
    match_country_image_path,
    league_logo_path,
    localTeam_logo,
    visitorTeam_logo,
    localteam_score,
    visitorteam_score,
  } = match;

  // Определяем путь к изображению страны
  const countryImagePath =
    match_country_name === "World" || match_country_name === "South America"
      ? worldImage
      : match_country_image_path;

  return (
    <Box className="match-card">
      <Box className="header">
        <img src={league_logo_path} alt={league_name} />
        <Typography className="league-name">{league_name}</Typography>
        <img src={countryImagePath} alt={match_country_name} />
      </Box>
      <Typography className="country-name">{match_country_name}</Typography>
      <Box className="teams">
        <Box className="team">
          <img className="team-logo" src={localTeam_logo} alt={localTeam} />
          <Typography variant="body2">{localTeam}</Typography>
        </Box>
        <Typography className="vs-text">VS</Typography>
        <Box className="team">
          <img className="team-logo" src={visitorTeam_logo} alt={visitorTeam} />
          <Typography variant="body2">{visitorTeam}</Typography>
        </Box>
      </Box>
      <Typography className="score">
        {localteam_score} : {visitorteam_score}
      </Typography>
      <Typography className="status">{status}</Typography>
      <Typography className="date-time">
        {date} {time}
      </Typography>
    </Box>
  );
};

MatchCard.propTypes = {
  match: PropTypes.shape({
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    match_country_name: PropTypes.string.isRequired,
    league_name: PropTypes.string.isRequired,
    localTeam: PropTypes.string.isRequired,
    visitorTeam: PropTypes.string.isRequired,
    match_country_image_path: PropTypes.string.isRequired,
    league_logo_path: PropTypes.string.isRequired,
    localTeam_logo: PropTypes.string.isRequired,
    visitorTeam_logo: PropTypes.string.isRequired,
    localteam_score: PropTypes.number.isRequired,
    visitorteam_score: PropTypes.number.isRequired,
  }).isRequired,
};

export default MatchCard;
