// ВИКТОРА КОД ----------------------------------------!!!!!
// import { useEffect, useContext } from "react";
// import { Routes, Route, Navigate, useLocation } from "react-router-dom";
// import { ThemeProvider } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";
// import theme from "assets/theme";
// import Presentation from "layouts/pages/presentation";
// import routes from "routes";
// import UserContext from "components/UserContext/UserContext";

// export default function App() {
//   const { pathname } = useLocation();
//   const { user } = useContext(UserContext);
//   useEffect(() => {
//     document.documentElement.scrollTop = 0;
//     document.scrollingElement.scrollTop = 0;
//   }, [pathname]);

//   const getRoutes = (allRoutes) =>
//     allRoutes.map((route) => {
//       if (route.collapse) {
//         return getRoutes(route.collapse);
//       }

//       if (route.route) {
//         return <Route exact path={route.route} element={route.component} key={route.key} />;
//       }
//       console.log("USER");
//       console.log(user);
//       return null;
//     });

//   return (
//     <ThemeProvider theme={theme}>
//       <CssBaseline />
//       <Routes>
//         {getRoutes(routes)}
//         <Route path="/presentation" element={<Presentation />} />
//         <Route path="*" element={<Navigate to="/presentation" />} />
//         {user && <Route path="*" element={<Navigate to="/authentication/login" />} />}
//       </Routes>
//     </ThemeProvider>
//   );
// }
// ВИКТОРА КОД ----------------------------------------!!!!!
import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";
import TelegramLogin from "components/TelegramLogin/TelegramLogin";

// Импорт маршрутов
import routes from "routes";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    console.log(getRoutes(routes));
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      console.log(route);
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="/" element={<Presentation />} />
        <Route path="/auth/telegram/callback" element={<TelegramLogin />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
}
