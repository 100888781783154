import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Grid } from "@mui/material";
import MatchCard from "../MatchCard/MatchCard";

const MatchList = () => {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const currentDate = new Date();
        const futureDate = new Date(currentDate.setDate(currentDate.getDate() + 0));
        const day = futureDate.getDate().toString().padStart(2, "0");
        const month = (futureDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
        const year = futureDate.getFullYear();
        const formattedDate = `${day}_${month}_${year}`;

        const response = await axios.get(
          `https://total93admin.com/prediction_events/${formattedDate}`,
          {
            headers: {
              Authorization: "Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd",
            },
          }
        );
        console.log("API Response:", response.data); // Лог данных API
        setMatches(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching match data:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchMatches();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!matches || matches.length === 0) {
    return <div>No matches found.</div>;
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        {matches.map((match, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <MatchCard match={match} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MatchList;
