import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Grid, Typography, Card, CardContent, Paper } from "@mui/material";

const StakingList = () => {
  const [matches, setMatches] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const response = await axios.get(
          `https://total93admin.com/prediction_single_events_for_telegram`,
          {
            headers: {
              Authorization: "Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd",
            },
          }
        );
        console.log("API Response:", response.data);
        setMatches(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching match data:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchMatches();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!matches) {
    return <div>No matches found.</div>;
  }

  const upcomingMatches = matches["Future Matches"] || [];
  const pastMatches = matches["Total Events Info"] || [];

  const formatDate = (dateString) => {
    const dateParts = dateString.split("-");
    const date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
    return date.toLocaleDateString("en-GB", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom align="center">
        Staking Power of Goal&apos;s Results
      </Typography>
      <Typography variant="body1" gutterBottom align="center">
        This section displays the results of the staking process with &apos;PRO&apos; Token&apos;s.
      </Typography>

      <Grid container spacing={2} sx={{ marginBottom: 4 }}>
        <Grid item xs={12} sm={4}>
          <Paper sx={{ padding: 2 }} elevation={3}>
            <Typography variant="h6">Total Power of Goal&apos;s</Typography>
            <Typography>{matches["Total Profit Percentage"]}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper sx={{ padding: 2 }} elevation={3}>
            <Typography variant="h6">Total Power of Goal&apos;s (Last 30 Days)</Typography>
            <Typography>{matches["Total Profit Percentage (Last 30 Days)"]}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper sx={{ padding: 2 }} elevation={3}>
            <Typography variant="h6">Total Events</Typography>
            <Typography>{matches["Total Events"]}</Typography>
          </Paper>
        </Grid>
      </Grid>

      <Typography variant="h5" gutterBottom>
        Upcoming Matches for Next 4 Days
      </Typography>
      <Grid container spacing={2}>
        {upcomingMatches.length > 0 ? (
          upcomingMatches.map((match, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="body2">{formatDate(match.date)}</Typography>
                  <Box display="flex" alignItems="center">
                    <img
                      src={match.country_logo}
                      alt={match.country}
                      style={{ width: 24, height: 24, marginRight: 8 }}
                    />
                    <Typography variant="body2">{match.country}</Typography>
                  </Box>
                  <Typography variant="body2">Status: {match.match_status}</Typography>
                  <Typography variant="body2">Expected Power: {match.expected_profit}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="body2">No upcoming matches.</Typography>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={4}>
          <Paper sx={{ padding: 2 }} elevation={3}>
            <Typography variant="h6">Future Total Events</Typography>
            <Typography>{matches["Future Total Events"]}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper sx={{ padding: 2 }} elevation={3}>
            <Typography variant="h6">Expected Power of Goal&apos;s</Typography>
            <Typography>{matches["Expected Goals"]}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper sx={{ padding: 2 }} elevation={3}>
            <Typography variant="h6">Total Power Expected</Typography>
            <Typography>{matches["Expected Profit"]}</Typography>
          </Paper>
        </Grid>
      </Grid>

      <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
        Past Matches - Last 30 Days
      </Typography>
      <Grid container spacing={2}>
        {pastMatches.length > 0 ? (
          pastMatches.map((match, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="body2">
                    {formatDate(match.info_event.date)} {match.info_event.time}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <img
                      src={match.info_event.match_country_image_path}
                      alt={match.info_event.country}
                      style={{ width: 24, height: 24, marginRight: 8 }}
                    />
                    <Typography variant="body2">{match.info_event.country}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <img
                      src={match.info_event.league_logo_path}
                      alt={match.info_event.championship}
                      style={{ width: 24, height: 24, marginRight: 8 }}
                    />
                    <Typography variant="body2">{match.info_event.championship}</Typography>
                  </Box>
                  <Typography variant="body2">Status: {match.info_event.match_status}</Typography>
                  <Typography variant="body2">
                    Full Time Score: {match.info_event.ft_score}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <img
                      src={match.info_event.localTeam_logo}
                      alt={match.info_event.team1}
                      style={{ width: 24, height: 24, marginRight: 8 }}
                    />
                    <Typography variant="body2">{match.info_event.team1}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <img
                      src={match.info_event.visitorTeam_logo}
                      alt={match.info_event.team2}
                      style={{ width: 24, height: 24, marginRight: 8 }}
                    />
                    <Typography variant="body2">{match.info_event.team2}</Typography>
                  </Box>
                  <Typography variant="body2">Power of Goal&apos;s: {match.info.profit}</Typography>
                  <Typography variant="body2">
                    Activity day: {match.info["activity day"]}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="body2">No past matches.</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default StakingList;
