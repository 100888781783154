// routes.js
// import React from "react";
import { FaTelegramPlane, FaSignOutAlt } from "react-icons/fa";
// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons
// import GitHubIcon from "@mui/icons-material/GitHub";

// Pages
// import AboutUs from "layouts/pages/landing-pages/about-us";
// import ContactUs from "layouts/pages/landing-pages/contact-us";
// import Author from "layouts/pages/landing-pages/author";
// import SignIn from "layouts/pages/authentication/sign-in";

// Sections
// import PageHeaders from "layouts/sections/page-sections/page-headers";
// import Features from "layouts/sections/page-sections/featuers";
// import Navbars from "layouts/sections/navigation/navbars";
// import NavTabs from "layouts/sections/navigation/nav-tabs";
// import Pagination from "layouts/sections/navigation/pagination";
// import Inputs from "layouts/sections/input-areas/inputs";
// import Forms from "layouts/sections/input-areas/forms";
// import Alerts from "layouts/sections/attention-catchers/alerts";
// import Modals from "layouts/sections/attention-catchers/modals";
// import TooltipsPopovers from "layouts/sections/attention-catchers/tooltips-popovers";
// import Avatars from "layouts/sections/elements/avatars";
// import Badges from "layouts/sections/elements/badges";
// import BreadcrumbsEl from "layouts/sections/elements/breadcrumbs";
// import Buttons from "layouts/sections/elements/buttons";
// import Dropdowns from "layouts/sections/elements/dropdowns";
// import ProgressBars from "layouts/sections/elements/progress-bars";
// import Toggles from "layouts/sections/elements/toggles";
// import Typography from "layouts/sections/elements/typography";

// Telegram Login
// import Login from "layouts/pages/authentication/login_telegram"; // Добавьте этот импорт
import TelegramLogin from "components/TelegramLogin/TelegramLogin"; // Убедитесь, что путь правильный
import Presentation from "layouts/pages/presentation";

const routes = [
  // {
  //   name: "pages",
  //   icon: <Icon>dashboard</Icon>,
  //   columns: 1,
  //   rowsPerColumn: 2,
  //   collapse: [
  //     {
  //       name: "landing pages",
  //       collapse: [
  //         {
  //           name: "about us",
  //           route: "/pages/landing-pages/about-us",
  //           component: <AboutUs />,
  //         },
  //         {
  //           name: "contact us",
  //           route: "/pages/landing-pages/contact-us",
  //           component: <ContactUs />,
  //         },
  //         {
  //           name: "author",
  //           route: "/pages/landing-pages/author",
  //           component: <Author />,
  //         },
  //         {
  //           name: "payment",
  //           route: "/payment",
  //           component: <PaymentForm />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    name: "Favorite Teams",
    icon: <Icon>view_day</Icon>,
    collapse: [
      {
        name: (
          <>
            <img
              src="https://media.api-sports.io/football/teams/10.png"
              alt="England"
              style={{ width: "20px", height: "15px", marginRight: "5px" }}
            />
            England
          </>
        ),
        description: "Premier League 2024/2025",
        dropdown: true,
        collapse: [
          { name: "Arsenal" },
          { name: "Aston Villa" },
          { name: "Chelsea" },
          { name: "Everton" },
          { name: "Fulham" },
          { name: "Liverpool" },
          { name: "Man City" },
          { name: "Man Utd" },
          { name: "Newcastle" },
          { name: "Tottenham" },
          { name: "West Ham" },
          { name: "Crystal Palace" },
          { name: "Ipswich" },
          { name: "Leicester" },
          { name: "Southampthon" },
          { name: "Wolves" },
          { name: "Bournemouth" },
          { name: "Brighton" },
          { name: "Nottm Forest" },
          { name: "Brentford" },
        ],
      },
      {
        name: (
          <>
            <img
              src="https://media.api-sports.io/football/teams/9.png"
              alt="Spain"
              style={{ width: "20px", height: "15px", marginRight: "5px" }}
            />
            Spain
          </>
        ),
        description: "La Liga 2024/2025",
        dropdown: true,
        collapse: [{ name: "Real Madrid" }, { name: "Barcelona" }, { name: "Girona" }],
      },
      {
        name: (
          <>
            <img
              src="https://media.api-sports.io/football/teams/768.png"
              alt="Italy"
              style={{ width: "20px", height: "15px", marginRight: "5px" }}
            />
            Italy
          </>
        ),
        description: "Serie A 2024/2025",
        dropdown: true,
        collapse: [{ name: "Inter" }, { name: "AC Milan" }],
      },
      {
        name: (
          <>
            <img
              src="https://media.api-sports.io/football/teams/25.png"
              alt="Germany"
              style={{ width: "20px", height: "15px", marginRight: "5px" }}
            />
            Germany
          </>
        ),
        description: "Bundesliga 2024/2025",
        dropdown: true,
        collapse: [{ name: "Leverkusen" }, { name: "Bayern" }, { name: "RB Leipzig" }],
      },
      {
        name: (
          <>
            <img
              src="https://media.api-sports.io/football/teams/2.png"
              alt="France"
              style={{ width: "20px", height: "15px", marginRight: "5px" }}
            />
            France
          </>
        ),
        description: "Ligue 1 2024/2025",
        dropdown: true,
        collapse: [
          { name: "PSG" },
          { name: "Lille" },
          { name: "Monaco" },
          { name: "Nice" },
          { name: "Lyon" },
          { name: "Lens" },
          { name: "Nantes" },
          { name: "Raims" },
        ],
      },
      {
        name: (
          <>
            <img
              src="https://media.api-sports.io/football/teams/27.png"
              alt="Portugal"
              style={{ width: "20px", height: "15px", marginRight: "5px" }}
            />
            Portugal
          </>
        ),
        description: "Liga Portugal 2024/2025",
        dropdown: true,
        collapse: [{ name: "Benfica" }, { name: "Braga" }, { name: "Porto" }],
      },
    ],
  },
  {
    name: "earn token",
    icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "start to earn token",
        description: "Choose favorite team",
        href: "https://proarena.io",
      },
      {
        name: "by crypto",
        description: "Buy crypto and mine X10",
        href: "https://proarena.io/",
      },
      {
        name: "staking",
        description: "Staking mode from 10%",
        href: "https://t.me/PRO_ARENA_bot?start=667fe171536b49d84300db71",
      },
      {
        name: "Get your NFTs",
        description: "Coming soon...",
        // href: "https://t.me/PRO_ARENA_bot?start=667fe171536b49d84300db71",
      },
      {
        name: "Referral earns",
        description: "Coming soon...",
        // href: "https://t.me/PRO_ARENA_bot?start=667fe171536b49d84300db71",
      },
    ],
  },
  // {
  //   name: "login", // Добавьте этот объект
  //   icon: <FaTelegramPlane color="inherit" />,
  //   route: "/pages/authentication/login",
  //   component: <Login />,
  // },
  {
    name: sessionStorage.getItem("user") ? "SignOut" : "Login with Telegram", // Название для кнопки логина
    icon: sessionStorage.getItem("user") ? (
      <FaSignOutAlt color="inherit" />
    ) : (
      <FaTelegramPlane color="inherit" />
    ), // Иконка для кнопки
    route: sessionStorage.getItem("user") ? "/" : "/auth/telegram/callback", // Указание правильного маршрута
    component: sessionStorage.getItem("user") ? <Presentation /> : <TelegramLogin />, // Убедитесь, что используется правильный компонент
  },
];

export default routes;
