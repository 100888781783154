// @mui material components
import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Presentation page components
// import ExampleCard from "pages/Presentation/components/ExampleCard";

// Data
import data from "pages/Presentation/sections/data/pagesData";

const imageStyle = {
  height: "200px", // Установите желаемую высоту
  objectFit: "cover", // Обрезка изображения по высоте
  width: "100%", // Убедитесь, что изображения заполняют всю ширину
  borderRadius: "12px", // Закругленные углы
};

function Pages() {
  const renderData = data.map(({ image, name }) => (
    <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }} key={name}>
      <div style={{ position: "relative", width: "100%" }}>
        <img src={image} alt={name} style={imageStyle} />
        <MKTypography
          variant="h5"
          fontWeight="bold"
          style={{ textAlign: "center", marginTop: "10px" }}
        >
          {name}
        </MKTypography>
      </div>
    </Grid>
  ));

  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h2" fontWeight="bold">
            Future view in PRO ARENA
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: { xs: 8, lg: 16 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <Grid container spacing={3}>
              {renderData}
            </Grid>
            <MKTypography variant="overline" color="text" mt={3} sx={{ fontSize: "0.7rem" }}>
              <br />
              <strong>DONE NOW</strong>
              <br />
              Start earning &quot;PRO&quot; Tokens daily as soon as you sign up.
              <br />
              Amplify your earnings by staking your tokens,
              <br />
              with potential returns of over 10x.
              <br />
              Join the community and grow your wealth effortlessly.
            </MKTypography>
            <MKTypography variant="overline" color="text" mt={3} sx={{ fontSize: "0.7rem" }}>
              <br />
              <strong>IN 4Q 2024</strong>
              <br />
              Coming soon...
              <br />
              Leverage our Referral System to create your own Fan Club for your favorite team.
              <br />
              Recruit friends, build a network, and earn additional &quot;PRO&quot;
              <br />
              Tokens with every new member.
            </MKTypography>
            <MKTypography variant="overline" color="text" mt={3} sx={{ fontSize: "0.7rem" }}>
              <br />
              <strong>IN 1Q 2025</strong>
              <br />
              NFTs & LootBoxes are on their way...
              <br />
              Acquire exclusive NFTs that empower you to mine more &quot;PRO&quot; Tokens
              <br />
              during events of your favorite team.
              <br />
              Enhance your fan experience with valuable digital assets.
            </MKTypography>
            <MKTypography variant="overline" color="text" mt={3} sx={{ fontSize: "0.7rem" }}>
              <br />
              <strong>IN 3Q 2025</strong>
              <br />
              Get ready for a game-changing opportunity...
              <br />
              Purchase equipment used by top professional players
              <br />
              and enjoy exclusive real-life interactions with the stars.
              <br />
              Elevate your fan journey to new heights.
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={3}>
            <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
              <MKTypography variant="h3" fontWeight="bold" mb={1}>
                Road map
              </MKTypography>
              <MKTypography variant="body1" color="text">
                Join our innovative platform to earn and stake &quot;PRO&quot;!
                <br />
                <br />
                Build your FAN CLUB, and engage with exclusive NFTs and professional players!
                <br />
                <br />
                Elevate your experience and maximize your rewards.
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Pages;
