import doneNowImage from "assets/images/about-us.jpg";
import in4Q2024Image from "assets/images/contact.jpg";
import in3Q2025Image from "assets/images/equipment.jpg";
import in1Q2025Image from "assets/images/nft.jpg";

export default [
  {
    image: doneNowImage,
    name: "DONE NOW",
  },
  {
    image: in4Q2024Image,
    name: "IN 4Q 2024",
  },
  {
    image: in1Q2025Image,
    name: "IN 1Q 2025",
  },
  {
    image: in3Q2025Image,
    name: "IN 3Q 2025",
  },
];
